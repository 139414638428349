import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/200.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";
import Cookies from "js-cookie";

export const onClientEntry = () => {
  window.onload = () => {
    const customerId = Cookies.get("global-prisma-customer-id") || "";
    const script = document.createElement("script");

    script.src =
      "https://emprisebank.prismacampaigns.com:443/sdk/javascript/prisma.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      prisma.load(
        "emprisebank.prismacampaigns.com",
        "443",
        "7f69b8d6-0d39-4ca8-88f9-fb1f28219fe3",
        customerId,
        [
          // {
          //   placeholderId: "DASHBOARD_LEADERBOARD_TOP",
          //   elementId: "placeholder",
          // },
        ],
        "https:"
      );
    };
  };
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === `production`) {
    setTimeout(() => {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: `gatsby-route-change`,
          pageTitle: document.title,
          pageUrl: location.href,
          pagePath: location.pathname,
        });
      }
    }, 100);
  }
};
